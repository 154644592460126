html,
body {
  font-family: "Open Sans", Arial, Helvetica;
  color: $text;
  min-width: 320px;
  line-height: 20px;
  max-width: 1600px;
  margin: 0 auto;
}

footer {
  padding: 30px 15px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.8);
  background: $grey;
  clear: both;
  margin-top: 45px;
}

.footer-copyright {
  font-size: 14px;
  text-align: right;
}

.footer__instagram-image {
  width: 64px;
  height: 64px;
  margin-top: 10px;
}

.sub-page {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .footer__instagram-image {
    margin-left: 10px;
    vertical-align: bottom;
    margin-top: 0;
  }

  footer {
    padding: 45px 30px 5px;
    margin-top: 60px;
  }

  .sub-page {
    flex-direction: row;
  }
}
