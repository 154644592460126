$grey: #dee0e2;
$black: #43484a;
$green: #008000;
$white: #fff;
$light-grey: rgb(211, 211, 211);
$red: #d60014;
$blue: #005ea5;
$darkblue: #1244a5;

$text: $black;
$link: $blue;
