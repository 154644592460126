.dialog-cookie {
  bottom: 0;
  position: fixed;
  z-index: 4;
  background: $white;
  padding: 30px;
  font-size: 14px;
  box-shadow: 0 0 5px $black;
  left: 0;
  right: 0;
  max-width: 1600px;
  margin: 0 auto;
}