.menu {
  margin-top: 45px;
}

.menu-item {
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.menu-item__wrapper {
  padding: 0 15px;
}

.menu-item__ingredients {
  font-weight: 300;
  font-size: 14px;
}

.menu-item__number {
  color: $red;
}

.menu-extras {
  margin-top: 15px;
}

.menu-extras__item {
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
}

.menu-extras__separator {
  color: $green;
  font-weight: 700;
}

.menu-extras__price {
  font-weight: 700;
}

@media (min-width: 768px) {
  .menu {
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
  }
  
  .menu-item {
    flex: 50%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .menu-item {
    flex: 33%;
  }

  .menu-item__wrapper {
    padding: 0 30px;
  }
}