.venue-info {
  margin: 30px 15px;
}

.venue-info__heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.venue-info__heading-small {
  font-size: 19px;
  font-weight: 700;
  margin: 30px 0 10px;
}

.venue-info__link {
  font-size: 34px;
  font-weight: 700;
  text-decoration: none;
  margin: 5px 0 15px;
  display: inline-block;
}

.venue-info__phone-code {
  font-size: 24px;
}

.venue-info__detailed-list {
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  max-width: 300px;
}

.venue-info__detailed-title {
  flex: 50%;
  margin: 5px 0 0;
}

.venue-info__detailed-description {
  flex: 50%;
  margin: 5px 0 0;
}

@media (min-width: 768px) {
  .venue-info {
    margin: 30px;
    display: inline-block;
    vertical-align: top;
    flex: 1 0 0;
  }
}

