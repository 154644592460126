.contact-section {
  position: relative;
  margin: 30px 0;
}

.contact-section__heading {
  font-weight: 700;
}

.contact-section__label {
  position: absolute;
  left: 0;
}

.contact-section__detail {
  padding-left: 60px;
}

.cookies-section {
  font-size: 14px;
  margin: 30px 0 45px;
}

.cookies-section__heading {
  font-weight: 700;
}

.main-section {
  padding: 45px 15px;
}

.main-section__heading {
  font-weight: 700;
  text-align: center;
  font-size: 24px;
}

a.main-section__asterisk {
  color: $red;
  text-decoration: none;
}

.main-section__heading:before {
  content: '\2014 ';
  color: $green;
  margin-right: 10px;
}

.main-section__heading:after {
  content: '\2014';
  color: $red;
  margin-left: 10px;
}

.sub-section {
  margin-bottom: 15px;
}

.sub-section__heading {
  font-weight: 700;
  text-align: center;
  font-size: 19px;
}

.sub-section__heading:before {
  content: '\2014 ';
  color: $green;
  margin-right: 10px;
}

.sub-section__heading:after {
  content: '\2014';
  color: $red;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .main-section {
    padding: 60px 30px;
  }
}
