.photo__list {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
}

.photo__item {
  flex: 100%;
  margin-bottom: 30px;
  text-align: center;
}

.photo__item-link {
  text-decoration: none;
}

.photo__item-link:active,
.photo__item-link:link,
.photo__item-link:visited,
.photo__item-link:hover {
  color: $black;
}

.photo__item-heading {
  font-weight: 700;
  text-align: center;
  font-size: 19px;
  margin: 30px 10% 15px;
}

.photo__item-heading--with-subheading {
  margin-bottom: 0;
}

.photo__item-image {
  width: 80%;
  height: auto;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  max-width: 300px;
}

.photo__item-image:hover {
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

.photo__item-subheading {
  font-size: 14px;
  margin-top: 15px;
  text-transform: uppercase;
}

.photo__item-telephone {
  font-weight: 700;
  font-size: 19px;
  display: block;
  margin-top: 5px;
  text-decoration: none;
}

.photo__item-telephone-code {
  font-size: 16px;
}

.photo__item-wolt {
  display: block;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 15px auto 15px;
  width: 180px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.photo__item-wolt:link,
.photo__item-wolt:visited {
  color: #000;
}

.photo__item-wolt:hover {
  border-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.photo__item-wolt:active {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.photo__item-wolt-image {
  width: 50px;
  height: auto;
  display: block;
  margin: 10px auto 0;
}

.photo__item-wolt--venue-info {
  margin: 15px 0 0 0;
}

@media (min-width: 360px) {
  .photo__item {
    flex: 50%;
  }
}

@media (min-width: 768px) {
  .photo__item {
    flex: 33%;
    margin-bottom: 45px;
  }
}
