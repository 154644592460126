.pfr-banner {
  height: auto;
  width: 100%;
  display: block;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.pfr-banner--desktop {
  display: none;
}

@media (min-width: 768px) {
  .pfr-banner--desktop {
    max-width: 1000px;
    display: block;
    margin: 0;
  }

  .pfr-banner--mobile {
    display: none;
  }
}
