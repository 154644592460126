.google-map {
  width: 100%;
  height: 400px;
}

@media (min-width: 768px) {
  .google-map {
    margin: 30px 0;
    flex: 2 0 0;
    width: 50%;
    display: inline-block;
  }
}