.delivery-info-wrapper {
  margin: 45px 0 0;
}

.delivery-info {
  margin-bottom: 15px;
  text-align: left;
}
.delivery-info.delivery-info--center {
  text-align: center;
  margin-bottom:30px;
}
.delivery-info__heading {
  font-size: 16px;
  font-weight: 700;
}

.delivery-info__paragraph {
  margin-bottom: 5px;
}

.delivery-info__paragraph--small {
  font-size: 12px;
}

.delivery-info__price {
  font-weight: 700;
}

.delivery-info__separator {
  color: $green;
  font-weight: 700;
}

@media (min-width: 768px) {
  .delivery-info-wrapper {
    justify-content: space-between;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .delivery-info.delivery-info--center {
    flex: 1 0 49%;
  }
}

@media (min-width: 1200px) {
  .delivery-info.delivery-info--center {
    flex: 1 0 33%;
  }
}