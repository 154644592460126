.carousel {
  margin: 0;
}

.glide__image {
  width: 100%;
  height: auto;
  min-height: 400px;
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.glide__arrows {
  position: absolute;
  top: 35%;
  left: 0;
  width: 100%;
}

.glide__arrow {
  margin: 30px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  padding: 30px;
  width: 50px;
  height: 50px;
  font-size: 29px;
  color: #000;
  opacity: 0.7;
  border: none;
  cursor: pointer;
  background: #fff;
}

.glide__bullet:focus,
.glide__bullet:hover,
.glide__bullet:active,
.glide__arrow:focus,
.glide__arrow:hover,
.glide__arrow:active {
  outline: none;
}

.glide__arrow:hover {
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.8);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.8);
  box-shadow: 0 0 10px rgba(0,0,0,0.8);
}

.glide__arrow:active {
  -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  -moz-box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  opacity: 1;
}

.glide__arrow--left {
  float: left;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.glide__arrow:before {
  content: "\203A";
  position: relative;
  left: -10px;
  top: -68px;
  font-size: 85px;
}

.glide__arrow--right {
  float: right;
}

.glide__bullets {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.glide__bullet {
  margin: 15px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 15px;
  width: 30px;
  height: 30px;
  opacity: 0.6;
  border: none;
  -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.8);
  -moz-box-shadow: 0 0 1px rgba(0,0,0,0.8);
  box-shadow: 0 0 1px rgba(0,0,0,0.8);
  cursor: pointer;
  background: #fff;
  -webkit-transition: background 0.1s;
  -moz-transition: background 0.1s;
  -ms-transition: background 0.1s;
  -o-transition: background 0.1s;
  transition: background 0.1s;

  &.glide__bullet--active {
    background: $red;
    opacity: 0.9;
  }
}

.glide__bullet:hover {
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.8);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.8);
  box-shadow: 0 0 10px rgba(0,0,0,0.8);
}

.glide__bullet:active {
  -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  -moz-box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  box-shadow: inset 0 0 1px rgba(0,0,0,0.8);
  opacity: 1;
}