p {
  margin: 5px 0 10px;
}

a:visited,
a:link {
  color: $blue;
}

.info-paragraph {
  font-size: 12px;
  max-width: 45em;
}

.info-paragraph-wrapper {
  margin-bottom: 30px;
}

.info-paragraph__asterisk {
  font-size: 19px;
  color: $red;
}

.center-paragraph {
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 768px) {
  p {
    margin: 5px 0 15px;
  }
}
