.navigation {
  position: relative;
}

.navigation__header {
  width: 100%;
  background: #fff;
  z-index: 2;
  position: relative;
}

.navigation__list {
  background: #fff;
  display: none;

  &.navigation__list--active {
    display: block;
  }

  &.navigation__list--expand {
    top: 85px;
  }

  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: -130px;
  left: 0;
  z-index: 1;
  -webkit-transition: top 0.5s ease-out;
  -moz-transition: top 0.5s ease-out;
  -o-transition: top 0.5s ease-out;
  transition: top 0.5s ease-out;
}

.navigation__item {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.navigation__link:link,
.navigation__link:visited {
  color: $text;
}

.navigation__link {
  text-decoration: none;
  color: $text;
  font-weight: 700;
  padding: 15px 60px;
  display: block;
}

.navigation__toggle {
  background-color: $white;
  cursor: pointer;
  padding: 10px;
  vertical-align: top;
}

.navigation__svg {
  stroke-width: 13;
  stroke-linecap: square;
  stroke: $red;
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  width: 60px;
  height: 60px;
}

.navigation__toggle[aria-pressed='true'] .navigation__svg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.navigation__heading {
  display: inline-block;
}

.navigation__logo {
  height: 60px;
  width: auto;
  vertical-align: top;
  padding: 10px;
}

.navigation__logo--desktop {
  display: none;
}

.navigation__logo--mobile {
  display: inline-block;
}

.navigation__facebook {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 13px 0 0;
  z-index: 2;
}

.navigation__item--active {
  .navigation__link {
    color: $green;
  }
}

.navigation__item:hover {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .navigation__logo--desktop {
    display: inline-block;
    padding: 10px 65px 10px 10px;
  }

  .navigation__logo--mobile {
    display: none;
  }

  .navigation__toggle {
    display: none;
  }

  .navigation__header {
    width: auto;
    display: inline-block;
    vertical-align: top;
  }

  .navigation__list {
    display: inline-block !important;
    position: relative;
    top: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: auto;
    margin: 20px 0;
    vertical-align: top;
    font-size: 0;

    &.navigation__list--expand {
      top: 0;
    }
  }

  .navigation__item {
    width: auto;
    display: inline-block;
    border-bottom: none;
    padding: 12px 15px;
    border-right: 1px solid $red;
    font-size: 16px;
  }

  .navigation__link {
    padding: 0;
  }

  .navigation__item:last-child {
    border-right: none;
  }
}
