.dialog-overlay {
  background: rgba(0,0,0,0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
}

.dialog {
  background: $white;
  margin: 30px;
  height: 80%;
  padding: 30px;
  overflow-y: scroll;
  font-size: 14px;
  box-shadow: 0 0 5px $black;
  position: relative;
}

@media (min-width: 768px) {
  .dialog {
    margin: 30px auto;
    z-index: 6;
    max-width: 600px;
    height: 85%;
  }
}

.dialog__close {
  color: $black;
  background: $white;
  padding: 10px;
  font-size: 32px;
  position: absolute;
  right: 10px;
  top: 0;
}

.dialog__close:hover,
.dialog__close:focus {
  background: $white;
}