.gallery {
  margin-bottom: 30px;
}

.gallery__images {
  margin: 30px 0 45px;
}

.gallery__image {
  width: 96%;
  height: auto;
  padding: 2%;
}

@media (min-width: 768px) {
  .gallery__image {
    width: 43%;
  }
}

@media (min-width: 1200px) {
  .gallery__image {
    width: 29%;
  }
}