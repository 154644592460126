.history {
  background: #333333;
  clear: both;
  color: $white;
  text-align: center;
}

.history__description {
  color: $light-grey;
  max-width: 30em;
  margin-left: auto;
  margin-right: auto;
}

.history__description--long {
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.history__image {
  height: auto;
  width: 60%;
  margin-top: 15px;
}

