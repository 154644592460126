.clean-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  outline: none;
  text-align: inherit;
}

/* Remove excess padding and border in Firefox 4+ */
.clean-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  margin: 0;
  text-decoration: none;
  background: $blue;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button:hover,
.button:focus {
  background: $darkblue;
}

.button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.button:active {
  transform: scale(0.99);
}

.bella-upmenu-button {
  background: #d60014;
  display: inline-block;
  position: absolute;
  border-radius: 5px;
  right: 80px;
  top: 15px;
  z-index: 100;
  width: 125px;
  padding: 2px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  display: none;

  &:link {
    color: #fff;
  }

  &:visited {
    color: #fff;
  }

  &:hover {
    color: #fff;
  }

  &:active {
    color: #fff;
  }

  .bella-upmenu-button__img {
    width: 50px;
    float: left;
  }

  .bella-upmenu-button__text {
    float: right;
    width: 75px;
    margin-top: 5px;
  }
}

.bella-upmenu-button--fixed {
  position: fixed;
  top: 100px;
  right: 0;
  border-radius: 5px 5px 0 0;

  transform: rotate(-90deg);
  transform-origin: right bottom;
  -ms-transform: rotate(-90deg);
  -ms-transform-origin: right bottom;
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: right bottom;

  &:hover {
    padding-bottom: 10px;
    top: 92px;
  }
}

.bella-upmenu-button--loaded {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
}
